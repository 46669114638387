import React from "react";

const Block = (props) => {
  return (
    <div className="py-4">
      <div
        className={
          "bg-white px-2 py-4 p-md-5 mb-5 shadow radius-10 " +
          (props.className ? props.className : "")
        }
      >
        {props.children}
      </div>
    </div>
  );
};

export default Block;
