import React, { useState } from "react";
import Block from "../Common/Block";
import BlockHeader from "../Common/BlockHeader";
import {
  DataGrid,
  GridActionsCellItem,
  GridAddIcon,
  GridColDef,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridToolbarContainer,
  nlNL,
} from "@mui/x-data-grid";
import {
  OvertimeWeekDTO,
  OvertimeWeekDtoTypeEnum,
  OvertimeWeekPutPostDTO,
  OvertimeWeekPutPostDtoTypeEnum,
} from "../../typescript/api/api";
import api from "../../services/api";
import moment from "moment";
import { Alert, Box, Button, Snackbar } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import useOvertimeWeeksByUser, {
  OvertimeWeekRowByUser,
} from "./hooks/useOvertimeWeeksByUser";
import useSnackbar from "../Common/hooks/useSnackbar";
import { calculateTotalHoursAndMinutesWholeWeek } from "./utility/calculateTotalHoursAndMinutesWholeWeek";
import TimeComponent from "../Common/datagrid/TimeComponent";
import Filter from "./OvertimeHour.View.Filter";
import { formatOvertimeTypeToString } from "./utility/formatOvertimeTypeToString";

type Props = {
  isAdmin: boolean;
};

const View = (props: Props) => {
  const [weekNumber, setWeekNumber] = useState(moment().week());
  const [year, setYear] = useState(moment().year());
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );
  const { snackbarOpen, snackbarMessage, showSnackbar, hideSnackbar } =
    useSnackbar();
  const { overtimeWeeksByWeekNumber, loading, setOvertimeWeeksByWeekNumber } =
    useOvertimeWeeksByUser(weekNumber, year);

  const mapOverTimeWeekDtoToOverTimeWeekRow = (
    overtimeWeek: OvertimeWeekDTO,
  ): OvertimeWeekRowByUser => {
    const parseTime = (time: string) =>
      time.includes("H")
        ? moment(time, "[PT]HH[H]mm[M]")
        : moment(time, "[PT]mm[M]");

    return {
      id: overtimeWeek.id,
      userId: overtimeWeek.userId,
      userUsername: overtimeWeek.userUsername,
      type: overtimeWeek.type,
      weekNumber: overtimeWeek.weekNumber,
      year: overtimeWeek.year,
      mondayTime: parseTime(overtimeWeek.mondayTime),
      tuesdayTime: parseTime(overtimeWeek.tuesdayTime),
      wednesdayTime: parseTime(overtimeWeek.wednesdayTime),
      thursdayTime: parseTime(overtimeWeek.thursdayTime),
      fridayTime: parseTime(overtimeWeek.fridayTime),
      saturdayTime: parseTime(overtimeWeek.saturdayTime),
      isNew: false,
    };
  };

  const mapToOvertimeWeekPutPostDtoTypeEnum = (
    type: OvertimeWeekDtoTypeEnum,
  ): OvertimeWeekPutPostDtoTypeEnum => {
    const typeMap: Record<
      OvertimeWeekDtoTypeEnum,
      OvertimeWeekPutPostDtoTypeEnum
    > = {
      [OvertimeWeekDtoTypeEnum.WORKED]: OvertimeWeekPutPostDtoTypeEnum.WORKED,
      [OvertimeWeekDtoTypeEnum.VACATION]:
        OvertimeWeekPutPostDtoTypeEnum.VACATION,
      [OvertimeWeekDtoTypeEnum.ADV]: OvertimeWeekPutPostDtoTypeEnum.ADV,
      [OvertimeWeekDtoTypeEnum.PAYED_LEAVE]:
        OvertimeWeekPutPostDtoTypeEnum.PAYED_LEAVE,
      [OvertimeWeekDtoTypeEnum.SICK]: OvertimeWeekPutPostDtoTypeEnum.SICK,
      [OvertimeWeekDtoTypeEnum.MEDICAL]: OvertimeWeekPutPostDtoTypeEnum.MEDICAL,
      [OvertimeWeekDtoTypeEnum.OVERTIME]:
        OvertimeWeekPutPostDtoTypeEnum.OVERTIME,
    };
    return typeMap[type];
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (typeof id === "number") {
      api.api
        .deleteOvertimeWeek(id)
        .then((response) => {
          setOvertimeWeeksByWeekNumber(
            overtimeWeeksByWeekNumber.filter((row) => row.id !== id),
          );
        })
        .catch((reason) => {
          showSnackbar(reason.response.data.message);
        });
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = overtimeWeeksByWeekNumber.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setOvertimeWeeksByWeekNumber(
        overtimeWeeksByWeekNumber.filter((row) => row.id !== id),
      );
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = async (
    newRow: OvertimeWeekRowByUser,
  ): Promise<OvertimeWeekRowByUser> => {
    const overtimeWeekPutPost: OvertimeWeekPutPostDTO = {
      ...newRow,
      type: mapToOvertimeWeekPutPostDtoTypeEnum(newRow.type),
      mondayTime: newRow.mondayTime.format("[PT]HH[H]mm[M]"),
      tuesdayTime: newRow.tuesdayTime.format("[PT]HH[H]mm[M]"),
      wednesdayTime: newRow.wednesdayTime.format("[PT]HH[H]mm[M]"),
      thursdayTime: newRow.thursdayTime.format("[PT]HH[H]mm[M]"),
      fridayTime: newRow.fridayTime.format("[PT]HH[H]mm[M]"),
      saturdayTime: newRow.saturdayTime.format("[PT]HH[H]mm[M]"),
    };

    try {
      let updatedRow: OvertimeWeekRowByUser;

      if (newRow.isNew) {
        const response = await api.api.addOvertimeWeek(overtimeWeekPutPost);
        updatedRow = mapOverTimeWeekDtoToOverTimeWeekRow(response.data);
      } else {
        const response = await api.api.updateOvertimeWeek(
          newRow.id,
          overtimeWeekPutPost,
        );
        updatedRow = mapOverTimeWeekDtoToOverTimeWeekRow(response.data);
      }

      setOvertimeWeeksByWeekNumber(
        overtimeWeeksByWeekNumber.map((row) =>
          row.id === newRow.id ? updatedRow : row,
        ),
      );

      return updatedRow;
    } catch (error) {
      showSnackbar(error.response.data.message);

      return error.response;
    }
  };

  const dayHeaders: Record<string, string> = {
    mondayTime: "Maandag",
    tuesdayTime: "Dinsdag",
    wednesdayTime: "Woensdag",
    thursdayTime: "Donderdag",
    fridayTime: "Vrijdag",
    saturdayTime: "Zaterdag",
  };

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 150,
      editable: true,
      type: "singleSelect",
      preProcessEditCellProps: (params) => {
        const hasError = overtimeWeeksByWeekNumber.some(
          (row) => row.type === params.props.value && row.id !== params.id,
        );
        if (hasError) showSnackbar("Type moet uniek zijn.");
        return { ...params.props, error: hasError };
      },
      valueOptions: Object.values(OvertimeWeekDtoTypeEnum).map((type) => ({
        value: type.valueOf(),
        label: formatOvertimeTypeToString(type),
      })),
      valueFormatter: (params) => formatOvertimeTypeToString(params.value),
    },
    ...[
      "mondayTime",
      "tuesdayTime",
      "wednesdayTime",
      "thursdayTime",
      "fridayTime",
      "saturdayTime",
    ].map((day) => ({
      field: day,
      headerName: dayHeaders[day],
      flex: 1,
      minWidth: 150,
      editable: true,
      renderCell: (params: GridRenderEditCellParams) => (
        <TimeComponent params={params} disabled={true} />
      ),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <TimeComponent params={params} />
      ),
    })),
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return isInEditMode
          ? [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{ color: "primary.main" }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                sx={{ color: "darkred" }}
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ]
          : [
              <GridActionsCellItem
                icon={<ModeEditIcon />}
                label="Edit"
                sx={{ color: "primary.main" }}
                onClick={handleEditClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                sx={{ color: "darkred" }}
                onClick={handleDeleteClick(id)}
                color="inherit"
              />,
            ];
      },
    },
  ];

  const totalHoursAndMinutes = calculateTotalHoursAndMinutesWholeWeek(
    overtimeWeeksByWeekNumber,
  );

  return (
    <Block>
      <BlockHeader>Uren</BlockHeader>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        key={"hours-snackbar"}
        autoHideDuration={5000}
        onClose={() => hideSnackbar()}
      >
        <Alert
          onClose={() => hideSnackbar()}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Filter
        year={year}
        setYear={setYear}
        weekNumber={weekNumber}
        setWeekNumber={setWeekNumber}
        totalHours={totalHoursAndMinutes.totalHours}
        totalMinutes={totalHoursAndMinutes.remainingMinutes}
      />
      <Box
        sx={{
          height: 600,
          width: "100%",
          "& .MuiDataGrid-row": {
            minHeight: "58px !important",
          },
          "& .MuiDataGrid-cell": {
            minHeight: "58px !important",
            padding: "0 10px !important;",
          },
        }}
      >
        <DataGrid
          localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
          editMode={"row"}
          rows={overtimeWeeksByWeekNumber}
          columns={columns}
          onRowEditStop={handleRowEditStop}
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          onRowModesModelChange={handleRowModesModelChange}
          loading={loading}
          getRowId={(row: OvertimeWeekRowByUser) => row.id}
          slots={{ toolbar: EditToolbar }}
          slotProps={{
            toolbar: {
              setOvertimeWeeksByWeekNumber,
              setRowModesModel,
              weekNumber,
              year,
            } as EditToolbarProps,
          }}
        />
      </Box>
    </Block>
  );
};

interface EditToolbarProps {
  setOvertimeWeeksByWeekNumber: React.Dispatch<
    React.SetStateAction<OvertimeWeekRowByUser[]>
  >;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  weekNumber: number;
  year: number;
}

function EditToolbar(props: EditToolbarProps) {
  const { setOvertimeWeeksByWeekNumber, setRowModesModel, weekNumber, year } =
    props;

  const handleClick = () => {
    const id = Math.random();
    setOvertimeWeeksByWeekNumber((oldRows) => [
      ...oldRows,
      {
        id,
        type: OvertimeWeekDtoTypeEnum.WORKED,
        weekNumber,
        year,
        isNew: true,
        mondayTime: moment("PT0H0M", "PT0H0M"),
        tuesdayTime: moment("PT0H0M", "PT0H0M"),
        wednesdayTime: moment("PT0H0M", "PT0H0M"),
        thursdayTime: moment("PT0H0M", "PT0H0M"),
        fridayTime: moment("PT0H0M", "PT0H0M"),
        saturdayTime: moment("PT0H0M", "PT0H0M"),
        userId: 0,
        userUsername: "",
      },
    ]);
    setRowModesModel((oldModel: GridRowModesModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "type" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<GridAddIcon />} onClick={handleClick}>
        Rij toevoegen
      </Button>
    </GridToolbarContainer>
  );
}

export default View;
