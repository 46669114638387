import React, { useEffect, useState } from "react";
import Block from "../Common/Block";
import BlockHeader from "../Common/BlockHeader";
import { DataGrid, GridColDef, GridToolbar, nlNL } from "@mui/x-data-grid";
import api from "../../services/api";
import Functions from "../../common/Functions";
import moment from "moment";
import NoAccessScreen from "../Common/NoAccessScreen";

type Props = {
  isAdmin: boolean;
};

interface FinishedMaterialRow {
  /** @format int64 */
  id?: number;
  name: string;
  /** @format double */
  price?: string;
  unit: string;
  /** @format float */
  amount?: number;
  /** @format int64 */
  moldId?: number;
  moldName?: string;
  moldDescription?: string;
  /** @format date-time */
  moldDate?: string;
  moldMoldNumber?: string;
  /** @format int64 */
  userId?: number;
  userUsername?: string;
}

const Overview = ({ isAdmin }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [materials, setMaterials] = useState<FinishedMaterialRow[]>([]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "name", headerName: "Naam", width: 150 },
    { field: "unit", headerName: "Eenheid", width: 150 },
    { field: "amount", headerName: "Aantal", width: 150 },
    { field: "price", headerName: "Materiaal prijs", width: 150 },
    { field: "totalPrice", headerName: "Totaal prijs", width: 150 },
    { field: "moldName", headerName: "Mal naam", width: 150 },
    { field: "moldMoldNumber", headerName: "Mal nummer", width: 150 },
    { field: "moldDate", headerName: "Datum", width: 150 },
    { field: "userUsername", headerName: "Medewerker", width: 150 },
  ];

  useEffect(() => {
    setLoading(true);
    api.api.getAllFinishedMaterials().then((response) => {
      const responseMaterials = response.data;

      setMaterials(
        responseMaterials.map((material) => ({
          ...material,
          price: Functions.euroFormat(material.price),
          totalPrice: Functions.euroFormat(
            material.price && material.amount
              ? material.price * material.amount
              : 0,
          ),
          moldDate: moment(material.moldDate).format("DD-MM-YYYY"),
        })),
      );

      setLoading(false);
    });
  }, []);

  return (
    <>
      {!isAdmin ? (
        <NoAccessScreen />
      ) : (
        <div className="col-12">
          <Block>
            <BlockHeader>Data Tabellen</BlockHeader>
            <h4 className="mb-3 ml-2">Materialen</h4>
            <DataGrid
              localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
              className="mb-5"
              loading={loading}
              rows={materials}
              slots={{ toolbar: GridToolbar }}
              columns={columns}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
            />
          </Block>
        </div>
      )}
    </>
  );
};

export default Overview;
