const NoAccessScreen = () => {
  return (
    <div className={"d-flex flex-column align-items-center"}>
      <h1>Geen toegang</h1>
      <p>Je hebt geen toegang tot deze pagina.</p>
    </div>
  );
};

export default NoAccessScreen;
