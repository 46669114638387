import React, { useEffect, useMemo, useState } from "react";

import UserService from "../../services/user.service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import Button from "../Form/Button";
import BlockHeader from "../Common/BlockHeader";
import Block from "../Common/Block";
import Table from "../Common/Table.js/Table";
import DeleteModal from "../Common/DeleteModal";
import NoAccessScreen from "../Common/NoAccessScreen";

const Overview = ({ isAdmin }) => {
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    UserService.getAllUsers().then((response) => {
      setUsers(response.data);
      setLoading(false);
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Gebruikersnaam",
        accessor: "username",
        width: "auto",
      },
      {
        header: "",
        accessor: "menu",
        width: "40",
        notClickable: true,
      },
    ],
    [],
  );

  const onRowClick = (row) => {
    navigate(`/user/form/${row.id}`);
  };

  const onDelete = (id) => {
    UserService.deleteUser(id).then((response) => {
      setUsers(users.filter((user) => user.id !== id));
      toggleDeleteModal();
    });
  };

  const renderTableValue = (row, column) => {
    if (column.accessor === "menu") {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            icon={faTrashAlt}
            className={"fs-20 color-light-red cursor-pointer"}
            onClick={() => toggleDeleteModal(row.id)}
          />
        </div>
      );
    }
    return <span>{row[column.accessor]}</span>;
  };

  const buttons = [
    <Button text={"Aanmaken"} onClick={() => navigate("/user/form")} />,
  ];

  const toggleDeleteModal = (id) => {
    setShowDeleteModal(!showDeleteModal);
    setDeleteId(id);
  };

  return (
    <>
      {!isAdmin ? (
        <NoAccessScreen />
      ) : (
        <div className="col-12 p-0 px-md-3">
          <DeleteModal
            show={showDeleteModal}
            title={"Medewerker verwijderen"}
            onSubmit={() => onDelete(deleteId)}
            onClose={toggleDeleteModal}
          >
            Weet je zeker dat je deze medewerker wilt verwijderen?
          </DeleteModal>
          <Block>
            <BlockHeader buttons={buttons}>Medewerkers</BlockHeader>
            <Table
              columns={columns}
              data={users}
              renderTableValue={renderTableValue}
              onRowClick={onRowClick}
              loading={loading}
            />
          </Block>
        </div>
      )}
    </>
  );
};

export default Overview;
