import BlockHeader from "../Common/BlockHeader";
import { Box } from "@mui/material";
import Block from "../Common/Block";
import React, { useEffect, useState } from "react";
import NoAccessScreen from "../Common/NoAccessScreen";
import useOvertimeWeeks, { OvertimeWeekRow } from "./hooks/useOvertimeWeeks";
import {
  DataGridPro,
  GridColDef,
  gridFilteredSortedRowIdsSelector,
  GridFilterModel,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { nlNL } from "@mui/x-data-grid-pro/locales";
import moment from "moment";
import { calculateTotalHoursAndMinutesWholeWeek } from "./utility/calculateTotalHoursAndMinutesWholeWeek";
import { formatOvertimeTypeToString } from "./utility/formatOvertimeTypeToString";

type Props = {
  isAdmin: boolean;
};

const OvertimeHourOverview = ({ isAdmin }: Props) => {
  const { overtimeWeeks, loading } = useOvertimeWeeks();
  const [filteredRows, setFilteredRows] =
    useState<OvertimeWeekRow[]>(overtimeWeeks);
  const [filterModel, setFilterModel] = useState({});
  const apiRef = useGridApiRef();

  const handleFilterModelChange = (newFilterModel: GridFilterModel) => {
    setFilterModel(newFilterModel);
  };

  useEffect(() => {
    const allRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleRows = overtimeWeeks.filter((row) =>
      allRowIds.includes(row.id),
    );
    setFilteredRows(visibleRows);
  }, [filterModel, overtimeWeeks]);

  const dayHeaders: Record<string, string> = {
    mondayTime: "Maandag uren",
    mondayDate: "Maandag datum",
    tuesdayTime: "Dinsdag uren",
    tuesdayDate: "Dinsdag datum",
    wednesdayTime: "Woensdag uren",
    wednesdayDate: "Woensdag datum",
    thursdayTime: "Donderdag uren",
    thursdayDate: "Donderdag datum",
    fridayTime: "Vrijdag uren",
    fridayDate: "Vrijdag datum",
    saturdayTime: "Zaterdag uren",
    saturdayDate: "Zaterdag datum",
  };

  const columns: GridColDef[] = [
    { field: "userUsername", headerName: "Medewerker", flex: 1, minWidth: 150 },
    { field: "year", headerName: "Jaar", flex: 1, minWidth: 150 },
    { field: "weekNumber", headerName: "Weeknummer", flex: 1, minWidth: 150 },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 150,
      type: "string",
      valueGetter: (value) => formatOvertimeTypeToString(value),
    },
    {
      field: "totalHours",
      headerName: "Totaal uren",
      flex: 1,
      minWidth: 150,
      type: "string",
    },
    ...[
      "mondayTime",
      "mondayDate",
      "tuesdayTime",
      "tuesdayDate",
      "wednesdayTime",
      "wednesdayDate",
      "thursdayTime",
      "thursdayDate",
      "fridayTime",
      "fridayDate",
      "saturdayTime",
      "saturdayDate",
    ].map((day) => ({
      field: day,
      headerName: dayHeaders[day],
      flex: 1,
      minWidth: 150,
      valueGetter: (value: moment.Moment) => {
        return day.includes("Time")
          ? value.format("HH:mm")
          : value.format("DD-MM-YYYY");
      },
    })),
  ];

  const { totalHours, remainingMinutes } =
    calculateTotalHoursAndMinutesWholeWeek(filteredRows);

  return (
    <>
      {!isAdmin ? (
        <NoAccessScreen />
      ) : (
        <Block>
          <BlockHeader>
            Overzicht Uren ({totalHours.toString().padStart(2, "0")}:
            {remainingMinutes.toString().padStart(2, "0")})
          </BlockHeader>
          <Box
            sx={{
              height: 800,
              width: "100%",
            }}
          >
            <DataGridPro
              localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
              slots={{ toolbar: GridToolbar }}
              rows={overtimeWeeks}
              columns={columns}
              loading={loading}
              getRowId={(row: OvertimeWeekRow) => row.id}
              onFilterModelChange={handleFilterModelChange}
              apiRef={apiRef}
            />
          </Box>
        </Block>
      )}
    </>
  );
};

export default OvertimeHourOverview;
