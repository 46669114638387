import { GridRenderEditCellParams } from "@mui/x-data-grid";
import moment from "moment/moment";
import { TimePicker } from "@mui/x-date-pickers";
import React from "react";

interface Props {
  params: GridRenderEditCellParams;
  disabled?: boolean;
}

const TimeComponent = ({ params, disabled }: Props) => {
  const { id, field, value, api } = params;

  const handleChange = (newValue: moment.Moment | null) => {
    api.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <TimePicker onChange={handleChange} value={value} disabled={disabled} />
  );
};

export default TimeComponent;
