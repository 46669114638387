import { useEffect, useState } from "react";
import moment from "moment";
import api from "../../../services/api";
import {
  OvertimeWeekDTO,
  OvertimeWeekDtoTypeEnum,
} from "../../../typescript/api/api";
import { calculateTotalHoursAndMinutes } from "../utility/calculateTotalHoursAndMinutesWholeWeek";

export interface OvertimeWeekRow {
  id: number;
  userId: number;
  userUsername: string;
  type: OvertimeWeekDtoTypeEnum;
  weekNumber?: number;
  year?: number;
  mondayTime: moment.Moment;
  mondayDate: moment.Moment;
  tuesdayTime: moment.Moment;
  tuesdayDate: moment.Moment;
  wednesdayTime: moment.Moment;
  wednesdayDate: moment.Moment;
  thursdayTime: moment.Moment;
  thursdayDate: moment.Moment;
  fridayTime: moment.Moment;
  fridayDate: moment.Moment;
  saturdayTime: moment.Moment;
  saturdayDate: moment.Moment;
  totalHours: string;
}

const useOvertimeWeeks = () => {
  const [overtimeWeeks, setOvertimeWeeks] = useState<OvertimeWeekRow[]>([]);
  const [loading, setLoading] = useState(false);

  const mapOverTimeWeekDtoToOverTimeWeekRow = (
    overtimeWeek: OvertimeWeekDTO,
  ): OvertimeWeekRow => {
    const parseTime = (time: string) => {
      if (time.includes("H")) {
        return moment(time, "[PT]HH[H]mm[M]");
      } else {
        return moment(time, "[PT]mm[M]");
      }
    };

    const mondayTime = parseTime(overtimeWeek.mondayTime);
    const tuesdayTime = parseTime(overtimeWeek.tuesdayTime);
    const wednesdayTime = parseTime(overtimeWeek.wednesdayTime);
    const thursdayTime = parseTime(overtimeWeek.thursdayTime);
    const fridayTime = parseTime(overtimeWeek.fridayTime);
    const saturdayTime = parseTime(overtimeWeek.saturdayTime);

    const { totalHours, remainingMinutes } = calculateTotalHoursAndMinutes(
      mondayTime,
      tuesdayTime,
      wednesdayTime,
      thursdayTime,
      fridayTime,
      saturdayTime,
    );

    return {
      id: overtimeWeek.id,
      userId: overtimeWeek.userId,
      userUsername: overtimeWeek.userUsername,
      type: overtimeWeek.type,
      weekNumber: overtimeWeek.weekNumber,
      year: overtimeWeek.year,
      mondayTime: mondayTime,
      mondayDate: moment(overtimeWeek.mondayDate),
      tuesdayTime: tuesdayTime,
      tuesdayDate: moment(overtimeWeek.tuesdayDate),
      wednesdayTime: wednesdayTime,
      wednesdayDate: moment(overtimeWeek.wednesdayDate),
      thursdayTime: thursdayTime,
      thursdayDate: moment(overtimeWeek.thursdayDate),
      fridayTime: fridayTime,
      fridayDate: moment(overtimeWeek.fridayDate),
      saturdayTime: saturdayTime,
      saturdayDate: moment(overtimeWeek.saturdayDate),
      totalHours:
        totalHours.toString().padStart(2, "0") +
        ":" +
        remainingMinutes.toString().padStart(2, "0"),
    };
  };

  const getAllOvertimeWeeks = () => {
    setLoading(true);

    api.api.getAllOvertimeWeeks().then((response) => {
      const overtimeWeeksInitial: OvertimeWeekRow[] = [];

      response.data.map((overtimeWeek: OvertimeWeekDTO) => {
        overtimeWeeksInitial.push(
          mapOverTimeWeekDtoToOverTimeWeekRow(overtimeWeek),
        );

        setOvertimeWeeks(overtimeWeeksInitial);
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    getAllOvertimeWeeks();
  }, []);

  return {
    overtimeWeeks,
    setOvertimeWeeks,
    loading,
  };
};

export default useOvertimeWeeks;
