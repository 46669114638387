/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ErrorResponse {
  /** @format int32 */
  status?: number;
  error?: string;
  message?: string;
  path?: string;
}

export interface SignupRequest {
  /**
   * @minLength 3
   * @maxLength 20
   */
  username: string;
  /** @uniqueItems true */
  role?: string[];
  /**
   * @minLength 6
   * @maxLength 40
   */
  password: string;
}

export interface OvertimeWeekPutPostDTO {
  type: OvertimeWeekPutPostDtoTypeEnum;
  /**
   * @format int32
   * @min 1
   * @max 52
   */
  weekNumber?: number;
  /**
   * @format int32
   * @min 1000
   * @max 9999
   */
  year?: number;
  /** @format duration */
  mondayTime: string;
  /** @format duration */
  tuesdayTime: string;
  /** @format duration */
  wednesdayTime: string;
  /** @format duration */
  thursdayTime: string;
  /** @format duration */
  fridayTime: string;
  /** @format duration */
  saturdayTime: string;
}

export interface OvertimeWeekDTO {
  /** @format int64 */
  id: number;
  /** @format int64 */
  userId: number;
  userUsername: string;
  type: OvertimeWeekDtoTypeEnum;
  /** @format int32 */
  weekNumber?: number;
  /** @format int32 */
  year?: number;
  /** @format int64 */
  mondayId: number;
  /** @format duration */
  mondayTime: string;
  /** @format date-time */
  mondayDate: string;
  /** @format int64 */
  tuesdayId: number;
  /** @format duration */
  tuesdayTime: string;
  /** @format date-time */
  tuesdayDate: string;
  /** @format int64 */
  wednesdayId: number;
  /** @format duration */
  wednesdayTime: string;
  /** @format date-time */
  wednesdayDate: string;
  /** @format int64 */
  thursdayId: number;
  /** @format duration */
  thursdayTime: string;
  /** @format date-time */
  thursdayDate: string;
  /** @format int64 */
  fridayId: number;
  /** @format duration */
  fridayTime: string;
  /** @format date-time */
  fridayDate: string;
  /** @format int64 */
  saturdayId: number;
  /** @format duration */
  saturdayTime: string;
  /** @format date-time */
  saturdayDate: string;
}

export interface MoldHour {
  /** @format int64 */
  id?: number;
  /** @format float */
  hours?: number;
  /** @format date-time */
  date?: string;
  user?: User;
  /** @format int64 */
  userId?: number;
}

export interface Role {
  /** @format int32 */
  id?: number;
  name?: RoleNameEnum;
}

export interface User {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 20
   */
  username: string;
  /** @uniqueItems true */
  roles?: Role[];
}

export interface MoldRequest {
  name: string;
  description: string;
  moldNumber: string;
  /** @format date-time */
  date?: string;
  finished?: boolean;
}

export interface MaterialRequest {
  name: string;
  /** @format double */
  price?: number;
  unit: string;
}

export interface MoldRegistrationRequest {
  /** @format float */
  amount?: number;
}

export interface MoldHourRequest {
  /** @format float */
  hours: number;
  /** @format date-time */
  date: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface TokenRefreshRequest {
  refreshToken: string;
}

export interface MoldDTO {
  /** @format int64 */
  id: number;
  name: string;
  description: string;
  /** @format date-time */
  date: string;
  finished?: boolean;
  moldNumber: string;
}

export interface FinishedMaterialDTO {
  /** @format int64 */
  id?: number;
  name: string;
  /** @format double */
  price?: number;
  unit: string;
  /** @format float */
  amount?: number;
  /** @format int64 */
  moldId?: number;
  moldName?: string;
  moldDescription?: string;
  /** @format date-time */
  moldDate?: string;
  moldMoldNumber?: string;
  /** @format int64 */
  userId?: number;
  userUsername?: string;
}

export enum OvertimeWeekPutPostDtoTypeEnum {
  WORKED = "WORKED",
  VACATION = "VACATION",
  ADV = "ADV",
  PAYED_LEAVE = "PAYED_LEAVE",
  SICK = "SICK",
  MEDICAL = "MEDICAL",
  OVERTIME = "OVERTIME",
}

export enum OvertimeWeekDtoTypeEnum {
  WORKED = "WORKED",
  VACATION = "VACATION",
  ADV = "ADV",
  PAYED_LEAVE = "PAYED_LEAVE",
  SICK = "SICK",
  MEDICAL = "MEDICAL",
  OVERTIME = "OVERTIME",
}

export enum RoleNameEnum {
  ROLE_USER = "ROLE_USER",
  ROLE_MODERATOR = "ROLE_MODERATOR",
  ROLE_ADMIN = "ROLE_ADMIN",
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "http://localhost:8081" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Tutorial Management API
 * @version 1.0
 * @baseUrl http://localhost:8081
 *
 * API of the JT Modelmakerij Web Application
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags user-controller
     * @name GetUserById
     * @request GET:/api/user/{id}
     * @secure
     */
    getUserById: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/user/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name UpdateUser
     * @request PUT:/api/user/{id}
     * @secure
     */
    updateUser: (id: number, data: SignupRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/user/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name DeleteUser
     * @request DELETE:/api/user/{id}
     * @secure
     */
    deleteUser: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/user/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags overtime-week-controller
     * @name UpdateOvertimeWeek
     * @request PUT:/api/overtime-weeks/{overtimeWeekId}
     * @secure
     */
    updateOvertimeWeek: (overtimeWeekId: number, data: OvertimeWeekPutPostDTO, params: RequestParams = {}) =>
      this.request<OvertimeWeekDTO, ErrorResponse>({
        path: `/api/overtime-weeks/${overtimeWeekId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags overtime-week-controller
     * @name DeleteOvertimeWeek
     * @request DELETE:/api/overtime-weeks/{overtimeWeekId}
     * @secure
     */
    deleteOvertimeWeek: (overtimeWeekId: number, params: RequestParams = {}) =>
      this.request<void, ErrorResponse>({
        path: `/api/overtime-weeks/${overtimeWeekId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mold-hour-controller
     * @name GetMoldHourById
     * @request GET:/api/moldhour/{id}
     * @secure
     */
    getMoldHourById: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/moldhour/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mold-hour-controller
     * @name UpdateMoldHour
     * @request PUT:/api/moldhour/{id}
     * @secure
     */
    updateMoldHour: (id: number, data: MoldHour, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/moldhour/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mold-hour-controller
     * @name DeleteMoldHour
     * @request DELETE:/api/moldhour/{id}
     * @secure
     */
    deleteMoldHour: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/moldhour/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mold-controller
     * @name GetMoldById
     * @request GET:/api/mold/{id}
     * @secure
     */
    getMoldById: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/mold/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mold-controller
     * @name UpdateMold
     * @request PUT:/api/mold/{id}
     * @secure
     */
    updateMold: (id: number, data: MoldRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/mold/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mold-controller
     * @name DeleteMoldById
     * @request DELETE:/api/mold/{id}
     * @secure
     */
    deleteMoldById: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/mold/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-controller
     * @name GetMaterialById
     * @request GET:/api/material/{id}
     * @secure
     */
    getMaterialById: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/material/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-controller
     * @name UpdateMaterial
     * @request PUT:/api/material/{id}
     * @secure
     */
    updateMaterial: (id: number, data: MaterialRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/material/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-controller
     * @name DeleteMaterial
     * @request DELETE:/api/material/{id}
     * @secure
     */
    deleteMaterial: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/material/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-registration-controller
     * @name GetMaterialRegistrationById
     * @request GET:/api/material-registration/{id}
     * @secure
     */
    getMaterialRegistrationById: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/material-registration/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-registration-controller
     * @name UpdateMaterialRegistrationAmount
     * @request PUT:/api/material-registration/{id}
     * @secure
     */
    updateMaterialRegistrationAmount: (id: number, data: MoldRegistrationRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/material-registration/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-registration-controller
     * @name DeleteMoldRegistrationById
     * @request DELETE:/api/material-registration/{id}
     * @secure
     */
    deleteMoldRegistrationById: (id: number, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/material-registration/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags overtime-week-controller
     * @name GetOvertimeWeeksCurrentUser
     * @request GET:/api/overtime-weeks
     * @secure
     */
    getOvertimeWeeksCurrentUser: (params: RequestParams = {}) =>
      this.request<OvertimeWeekDTO[], ErrorResponse>({
        path: `/api/overtime-weeks`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags overtime-week-controller
     * @name AddOvertimeWeek
     * @request POST:/api/overtime-weeks
     * @secure
     */
    addOvertimeWeek: (data: OvertimeWeekPutPostDTO, params: RequestParams = {}) =>
      this.request<OvertimeWeekDTO, ErrorResponse>({
        path: `/api/overtime-weeks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mold-controller
     * @name GetAllMolds
     * @request GET:/api/mold
     * @secure
     */
    getAllMolds: (
      query: {
        finished: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<MoldDTO[], ErrorResponse>({
        path: `/api/mold`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mold-controller
     * @name AddMold
     * @request POST:/api/mold
     * @secure
     */
    addMold: (data: MoldRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/mold`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mold-controller
     * @name AddMoldHourToMold
     * @request POST:/api/mold/{id}/moldhour
     * @secure
     */
    addMoldHourToMold: (id: number, data: MoldHourRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/mold/${id}/moldhour`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-controller
     * @name GetAllMaterials
     * @request GET:/api/material
     * @secure
     */
    getAllMaterials: (params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/material`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-controller
     * @name AddMaterial
     * @request POST:/api/material
     * @secure
     */
    addMaterial: (data: MaterialRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/material`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-registration-controller
     * @name AddMaterialToMold
     * @request POST:/api/material-registration/{moldId}/{materialId}
     * @secure
     */
    addMaterialToMold: (
      moldId: number,
      materialId: number,
      data: MoldRegistrationRequest,
      params: RequestParams = {},
    ) =>
      this.request<object, ErrorResponse>({
        path: `/api/material-registration/${moldId}/${materialId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-controller
     * @name RegisterUser
     * @request POST:/api/auth/signup
     * @secure
     */
    registerUser: (data: SignupRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/auth/signup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-controller
     * @name AuthenticateUser
     * @request POST:/api/auth/signin
     * @secure
     */
    authenticateUser: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/auth/signin`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth-controller
     * @name Refreshtoken
     * @request POST:/api/auth/refreshtoken
     * @secure
     */
    refreshtoken: (data: TokenRefreshRequest, params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/auth/refreshtoken`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetUserByUsername
     * @request GET:/api/user
     * @secure
     */
    getUserByUsername: (params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/user`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetAllUsers
     * @request GET:/api/user/all
     * @secure
     */
    getAllUsers: (params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/user/all`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-controller
     * @name UserAccess
     * @request GET:/api/test/user
     * @secure
     */
    userAccess: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/api/test/user`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-controller
     * @name ModeratorAccess
     * @request GET:/api/test/mod
     * @secure
     */
    moderatorAccess: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/api/test/mod`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-controller
     * @name AllAccess
     * @request GET:/api/test/all
     * @secure
     */
    allAccess: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/api/test/all`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags test-controller
     * @name AdminAccess
     * @request GET:/api/test/admin
     * @secure
     */
    adminAccess: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/api/test/admin`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags overtime-week-controller
     * @name GetAllOvertimeWeeks
     * @request GET:/api/overtime-weeks/all
     * @secure
     */
    getAllOvertimeWeeks: (params: RequestParams = {}) =>
      this.request<OvertimeWeekDTO[], ErrorResponse>({
        path: `/api/overtime-weeks/all`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-controller
     * @name GetAllFinishedMaterials
     * @request GET:/api/material/finished
     * @secure
     */
    getAllFinishedMaterials: (params: RequestParams = {}) =>
      this.request<FinishedMaterialDTO[], ErrorResponse>({
        path: `/api/material/finished`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags material-registration-controller
     * @name GetAllMaterialRegistrations
     * @request GET:/api/material-registration
     * @secure
     */
    getAllMaterialRegistrations: (params: RequestParams = {}) =>
      this.request<object, ErrorResponse>({
        path: `/api/material-registration`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  image = {
    /**
     * No description
     *
     * @tags image-controller
     * @name GetImage
     * @request GET:/image/{image_name}
     * @secure
     */
    getImage: (imageName: string, params: RequestParams = {}) =>
      this.request<File, ErrorResponse>({
        path: `/image/${imageName}`,
        method: "GET",
        secure: true,
        format: "blob",
        ...params,
      }),
  };
  robotTxt = {
    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt
     * @request GET:/robot.txt
     * @secure
     */
    getRobotsTxt: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robot.txt`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt3
     * @request PUT:/robot.txt
     * @secure
     */
    getRobotsTxt3: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robot.txt`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt2
     * @request POST:/robot.txt
     * @secure
     */
    getRobotsTxt2: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robot.txt`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt5
     * @request DELETE:/robot.txt
     * @secure
     */
    getRobotsTxt5: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robot.txt`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt6
     * @request OPTIONS:/robot.txt
     * @secure
     */
    getRobotsTxt6: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robot.txt`,
        method: "OPTIONS",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt1
     * @request HEAD:/robot.txt
     * @secure
     */
    getRobotsTxt1: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robot.txt`,
        method: "HEAD",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt4
     * @request PATCH:/robot.txt
     * @secure
     */
    getRobotsTxt4: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robot.txt`,
        method: "PATCH",
        secure: true,
        ...params,
      }),
  };
  robotsTxt = {
    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt7
     * @request GET:/robots.txt
     * @secure
     */
    getRobotsTxt7: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robots.txt`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt10
     * @request PUT:/robots.txt
     * @secure
     */
    getRobotsTxt10: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robots.txt`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt9
     * @request POST:/robots.txt
     * @secure
     */
    getRobotsTxt9: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robots.txt`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt12
     * @request DELETE:/robots.txt
     * @secure
     */
    getRobotsTxt12: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robots.txt`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt13
     * @request OPTIONS:/robots.txt
     * @secure
     */
    getRobotsTxt13: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robots.txt`,
        method: "OPTIONS",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt8
     * @request HEAD:/robots.txt
     * @secure
     */
    getRobotsTxt8: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robots.txt`,
        method: "HEAD",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags robot-controller
     * @name GetRobotsTxt11
     * @request PATCH:/robots.txt
     * @secure
     */
    getRobotsTxt11: (params: RequestParams = {}) =>
      this.request<string, ErrorResponse>({
        path: `/robots.txt`,
        method: "PATCH",
        secure: true,
        ...params,
      }),
  };
}
