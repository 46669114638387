import { OvertimeWeekDtoTypeEnum } from "../../../typescript/api/api";

export const formatOvertimeTypeToString = (
  type: OvertimeWeekDtoTypeEnum,
): string => {
  const typeMap: Record<OvertimeWeekDtoTypeEnum, string> = {
    [OvertimeWeekDtoTypeEnum.WORKED]: "Gewerkt",
    [OvertimeWeekDtoTypeEnum.VACATION]: "Snipperuren",
    [OvertimeWeekDtoTypeEnum.ADV]: "ADV",
    [OvertimeWeekDtoTypeEnum.PAYED_LEAVE]: "Betaald verlof",
    [OvertimeWeekDtoTypeEnum.SICK]: "Ziek",
    [OvertimeWeekDtoTypeEnum.MEDICAL]: "Medisch",
    [OvertimeWeekDtoTypeEnum.OVERTIME]: "Overwerk",
  };
  return typeMap[type] || "Onbekend";
};
