import { OvertimeWeekRowByUser } from "../hooks/useOvertimeWeeksByUser";
import { OvertimeWeekRow } from "../hooks/useOvertimeWeeks";
import moment from "moment";

export const calculateTotalHoursAndMinutesWholeWeek = (
  rows: (OvertimeWeekRowByUser | OvertimeWeekRow)[],
) => {
  let totalMinutes = 0;

  rows.forEach((row) => {
    totalMinutes += row.mondayTime.hours() * 60 + row.mondayTime.minutes();
    totalMinutes += row.tuesdayTime.hours() * 60 + row.tuesdayTime.minutes();
    totalMinutes +=
      row.wednesdayTime.hours() * 60 + row.wednesdayTime.minutes();
    totalMinutes += row.thursdayTime.hours() * 60 + row.thursdayTime.minutes();
    totalMinutes += row.fridayTime.hours() * 60 + row.fridayTime.minutes();
    totalMinutes += row.saturdayTime.hours() * 60 + row.saturdayTime.minutes();
  });

  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  return { totalHours, remainingMinutes };
};

export const calculateTotalHoursAndMinutes = (
  mondayTime: moment.Moment,
  tuesdayTime: moment.Moment,
  wednesdayTime: moment.Moment,
  thursdayTime: moment.Moment,
  fridayTime: moment.Moment,
  saturdayTime: moment.Moment,
) => {
  let totalMinutes = 0;

  totalMinutes += mondayTime.hours() * 60 + mondayTime.minutes();
  totalMinutes += tuesdayTime.hours() * 60 + tuesdayTime.minutes();
  totalMinutes += wednesdayTime.hours() * 60 + wednesdayTime.minutes();
  totalMinutes += thursdayTime.hours() * 60 + thursdayTime.minutes();
  totalMinutes += fridayTime.hours() * 60 + fridayTime.minutes();
  totalMinutes += saturdayTime.hours() * 60 + saturdayTime.minutes();

  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  return { totalHours, remainingMinutes };
};
