import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRightFromBracket,
  faChartPie,
  faClock,
  faClockRotateLeft,
  faHammer,
  faPencilRuler,
  faTable,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import logo from "./images/logo.png";
import moment from "moment";
import "moment/locale/nl";

import AuthService from "./services/auth.service";

import Login from "./components/Login";
import Profile from "./components/Profile";

import EventBus from "./common/EventBus";
import User from "./components/User/User";
import Material from "./components/Materials/Material";
import Mold from "./components/Mold/Mold";
import Data from "./components/data/Data";
import OvertimeHour from "./components/OvertimeHour/OvertimeHour";
import Graph from "./components/graph/Graph";

const App = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const navigate = useNavigate();

  const logOut = () => {
    AuthService.logout();
    setCurrentUser(undefined);
    setIsAdmin(false);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    moment.locale("nl");

    if (user) {
      setCurrentUser(user);
      setIsAdmin(user.roles.includes("ROLE_ADMIN"));
    }

    EventBus.on("logout", () => {
      logOut();
      navigate("/login");
    });

    return () => {
      EventBus.remove("logout");
    };
    //eslint-disable-next-line
  }, []);

  const navItems = [
    { to: "/mold", icon: faPencilRuler, label: "Mallen" },
    { to: "/overtime-hour/user", icon: faClock, label: "Uren" },
  ];

  const adminNavItems = [
    { to: "/material", icon: faHammer, label: "Materialen" },
    { to: "/user", icon: faUserPlus, label: "Medewerkers" },
    { to: "/overtime-hour", icon: faClockRotateLeft, label: "Uren overzicht" },
    { to: "/data", icon: faTable, label: "Data tabellen" },
    { to: "/graphs", icon: faChartPie, label: "Data grafieken" },
  ];

  return (
    <div className="app-container">
      {!currentUser ? (
        <Login />
      ) : (
        <div>
          <nav className="navbar navbar-expand navbar-light bg-white">
            <div className="container-fluid">
              <Link to={"/"} className="navbar-brand">
                <img src={logo} alt="logo" className="nav-logo" />
              </Link>
              <div className="navbar-nav mr-auto">
                {navItems.map((item) => (
                  <li className="nav-item" key={item.to}>
                    <Link
                      to={item.to}
                      className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                    >
                      <FontAwesomeIcon
                        icon={item.icon}
                        className="fs-30 color-light-red mb-1"
                      />
                      <span className="fs-14">{item.label}</span>
                    </Link>
                  </li>
                ))}
                {isAdmin &&
                  adminNavItems.map((item) => (
                    <li className="nav-item" key={item.to}>
                      <Link
                        to={item.to}
                        className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          className="fs-30 color-light-red mb-1"
                        />
                        <span className="fs-14">{item.label}</span>
                      </Link>
                    </li>
                  ))}
              </div>

              <div className="navbar-nav ms-auto ">
                <li className="nav-item">
                  <Link
                    to={"/"}
                    className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      className={"fs-30 color-light-red mb-1"}
                    />
                    <span className="fs-14 first-uppercase">
                      {currentUser.username}
                    </span>
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="/"
                    className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                    onClick={logOut}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRightFromBracket}
                      className={"fs-30 color-light-red mb-1"}
                    />
                    <span className="fs-14">Uitloggen</span>
                  </a>
                </li>
              </div>
            </div>
          </nav>

          <div className="container mt-3">
            <Routes>
              <Route path="/" element={<Profile />} />
              <Route path="/user/*" element={<User isAdmin={isAdmin} />} />
              <Route
                path="/material/*"
                element={<Material isAdmin={isAdmin} />}
              />
              <Route path="/mold/*" element={<Mold isAdmin={isAdmin} />} />
              <Route
                path="/overtime-hour/*"
                element={<OvertimeHour isAdmin={isAdmin} />}
              />
              <Route path="/data/*" element={<Data isAdmin={isAdmin} />} />
              <Route path="/graphs/*" element={<Graph isAdmin={isAdmin} />} />
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
