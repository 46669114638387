import React from "react";
import { Route, Routes } from "react-router-dom";
import Form from "./Material.Form";
import Overview from "./Material.Overview";

const Material = ({ isAdmin }) => {
  return (
    <Routes>
      <Route path="/" element={<Overview isAdmin />} />
      <Route path="/form" element={<Form />} />
      <Route path="/form/:id" element={<Form />} />
    </Routes>
  );
};

export default Material;
