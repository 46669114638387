import { useEffect, useState } from "react";
import moment from "moment";
import api from "../../../services/api";
import {
  OvertimeWeekDTO,
  OvertimeWeekDtoTypeEnum,
} from "../../../typescript/api/api";

export interface OvertimeWeekRowByUser {
  id: number;
  userId: number;
  userUsername: string;
  type: OvertimeWeekDtoTypeEnum;
  weekNumber?: number;
  year?: number;
  mondayTime: moment.Moment;
  tuesdayTime: moment.Moment;
  wednesdayTime: moment.Moment;
  thursdayTime: moment.Moment;
  fridayTime: moment.Moment;
  saturdayTime: moment.Moment;
  isNew: boolean;
}

const useOvertimeWeeksByUser = (weekNumber: number, year: number) => {
  const [overtimeWeeksByWeekNumber, setOvertimeWeeksByWeekNumber] = useState<
    OvertimeWeekRowByUser[]
  >([]);
  const [loading, setLoading] = useState(false);

  const mapOverTimeWeekDtoToOverTimeWeekRow = (
    overtimeWeek: OvertimeWeekDTO,
  ): OvertimeWeekRowByUser => {
    const parseTime = (time: string) => {
      if (time.includes("H")) {
        return moment(time, "[PT]HH[H]mm[M]");
      } else {
        return moment(time, "[PT]mm[M]");
      }
    };

    return {
      id: overtimeWeek.id,
      userId: overtimeWeek.userId,
      userUsername: overtimeWeek.userUsername,
      type: overtimeWeek.type,
      weekNumber: overtimeWeek.weekNumber,
      year: overtimeWeek.year,
      mondayTime: parseTime(overtimeWeek.mondayTime),
      tuesdayTime: parseTime(overtimeWeek.tuesdayTime),
      wednesdayTime: parseTime(overtimeWeek.wednesdayTime),
      thursdayTime: parseTime(overtimeWeek.thursdayTime),
      fridayTime: parseTime(overtimeWeek.fridayTime),
      saturdayTime: parseTime(overtimeWeek.saturdayTime),
      isNew: false,
    };
  };

  const getAllOvertimeWeeks = () => {
    setLoading(true);

    api.api.getOvertimeWeeksCurrentUser().then((response) => {
      const overtimeWeeksInitial: OvertimeWeekRowByUser[] = [];

      response.data.map((overtimeWeek: OvertimeWeekDTO) => {
        overtimeWeeksInitial.push(
          mapOverTimeWeekDtoToOverTimeWeekRow(overtimeWeek),
        );
      });

      getOvertimeWeeksByWeekNumber(weekNumber, year, overtimeWeeksInitial);
      setLoading(false);
    });
  };

  const getOvertimeWeeksByWeekNumber = (
    weekNumber: number,
    year: number,
    overtimeWeeks: OvertimeWeekRowByUser[] = [],
  ) => {
    const filteredWeeks = overtimeWeeks.filter((overtimeWeek) => {
      return (
        overtimeWeek.weekNumber === weekNumber && overtimeWeek.year === year
      );
    });
    setOvertimeWeeksByWeekNumber(filteredWeeks);
  };

  useEffect(() => {
    getAllOvertimeWeeks();
  }, [weekNumber, year]);

  return {
    overtimeWeeksByWeekNumber,
    loading,
    setOvertimeWeeksByWeekNumber,
  };
};

export default useOvertimeWeeksByUser;
