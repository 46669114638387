import React from "react";
import { Route, Routes } from "react-router-dom";
import View from "./OvertimeHour.View";
import OvertimeHourOverview from "./OvertimeHour.Overview";

const OvertimeHour = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<OvertimeHourOverview isAdmin={props.isAdmin} />}
      />
      <Route path="/user" element={<View isAdmin={props.isAdmin} />} />
    </Routes>
  );
};

export default OvertimeHour;
