import React from "react";
import { Route, Routes } from "react-router-dom";
import Form from "./User.Form";
import Overview from "./User.Overview";

const User = ({ isAdmin }) => {
  return (
    <Routes>
      <Route path="/" element={<Overview isAdmin />} />
      <Route path="/form" element={<Form />} />
      <Route path="/form/:id" element={<Form />} />
    </Routes>
  );
};

export default User;
