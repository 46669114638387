import React, { SyntheticEvent, useEffect, useState } from "react";
import Block from "../Common/Block";
import BlockHeader from "../Common/BlockHeader";
import api from "../../services/api";
import { Box, Tab, Tabs } from "@mui/material";
import moment from "moment";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import NoAccessScreen from "../Common/NoAccessScreen";

type Props = {
  isAdmin: boolean;
};

interface MaterialYearGroup {
  [year: string]: MaterialMonthGroup;
}

interface MaterialMonthGroup {
  [month: string]: number;
}

const Overview = ({ isAdmin }: Props) => {
  const [materialGroupedByYear, setMaterialGroupedByYear] = useState<
    [string, [string, number][]][]
  >([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    api.api.getAllFinishedMaterials().then((response) => {
      const responseMaterials = response.data;
      console.log(responseMaterials);
      let group: MaterialYearGroup = {};

      for (let i in responseMaterials) {
        const year = moment(responseMaterials[i].moldDate).year();
        const month = moment(responseMaterials[i].moldDate).format("MMMM");

        const totalPrice: number =
          (responseMaterials[i].amount || 0) *
          (responseMaterials[i].price || 0);

        group[year] = group[year] === undefined ? {} : group[year];
        group[year][month] =
          group[year][month] === undefined
            ? totalPrice
            : group[year][month] + totalPrice;
      }

      let groupedArrayYear: [string, MaterialMonthGroup][] =
        Object.entries(group);

      let groupedArrayMonth: [string, [string, number][]][] = [];
      for (let i in groupedArrayYear) {
        groupedArrayMonth.push([
          groupedArrayYear[i][0],
          Object.entries(groupedArrayYear[i][1]),
        ]);
      }

      setTabValue(groupedArrayMonth.length - 1);
      setMaterialGroupedByYear(groupedArrayMonth);
    });
  }, []);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      {!isAdmin ? (
        <NoAccessScreen />
      ) : (
        <div className="col-12">
          <Block>
            <BlockHeader>Grafieken</BlockHeader>
            <div className="row">
              <div className="col-12">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    {materialGroupedByYear.map((material) => {
                      return (
                        <Tab label={material[0]} key={"tab" + material[0]} />
                      );
                    })}
                  </Tabs>
                </Box>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {materialGroupedByYear.map((material, index) => {
                  return (
                    <CustomTabPanel
                      value={tabValue}
                      index={index}
                      key={"tabpanel-" + material[0]}
                    >
                      <MaterialByMonthGraph materialsGrouped={material[1]} />
                    </CustomTabPanel>
                  );
                })}
              </div>
            </div>
          </Block>
        </div>
      )}
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface MaterialByMonthGraphProps {
  materialsGrouped: [string, number][];
}

const MaterialByMonthGraph = (props: MaterialByMonthGraphProps) => {
  const { materialsGrouped } = props;
  const sortedMaterialsGrouped = materialsGrouped.sort((a, b) => {
    return (
      parseInt(moment().month(a[0]).format("M")) -
      parseInt(moment().month(b[0]).format("M"))
    );
  });
  let labelsData: string[] = [];
  let valueData: number[] = [];
  let monthNumbers: number[] = [];

  sortedMaterialsGrouped.forEach((material) => {
    labelsData.push(material[0]);
    valueData.push(material[1]);
    monthNumbers.push(parseInt(moment().month(material[0]).format("M")));
  });

  return (
    <>
      <BarChart
        xAxis={[
          {
            id: "matrialsGroupedByMonth",
            data: labelsData,
            scaleType: "band",
            label: "Maand",
          },
        ]}
        series={[
          {
            data: valueData,
            label: "Materiaal kosten in euro's",
          },
        ]}
        width={1000}
        height={400}
      />
      <LineChart
        xAxis={[{ data: monthNumbers, label: "Maand nummer" }]}
        series={[
          {
            data: valueData,
            label: "Materiaal kosten in euro's",
          },
        ]}
        width={1000}
        height={400}
      />
    </>
  );
};

export default Overview;
