import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import moment from "moment/moment";

type Props = {
  year: number;
  weekNumber: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
  setWeekNumber: React.Dispatch<React.SetStateAction<number>>;
  totalHours: number;
  totalMinutes: number;
};

const Filter = ({
  year,
  weekNumber,
  setYear,
  setWeekNumber,
  totalHours,
  totalMinutes,
}: Props) => {
  const handleChangeYear = (event: SelectChangeEvent) => {
    setYear(Number(event.target.value));
  };

  const handleChangeWeekNumber = (event: SelectChangeEvent) => {
    setWeekNumber(Number(event.target.value));
  };

  return (
    <Box>
      <div className={"row d-flex justify-content-center  mb-3"}>
        <div className={"col-6 col-md-2 mb-2 mb-md-0"}>
          <FormControl fullWidth={true}>
            <InputLabel id="filter-jaar-label">Jaar</InputLabel>
            <Select
              id={"filter-jaar-select"}
              value={year.toString()}
              onChange={handleChangeYear}
            >
              {Array.from({ length: 50 }, (_, i) => (
                <MenuItem
                  key={"filter-jaar-select-menu-item" + i}
                  value={moment().year() - i}
                >
                  {moment().year() - i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={"col-6 col-md-2"}>
          <FormControl fullWidth={true}>
            <InputLabel id="filter-weeknumber-label">Weeknummer</InputLabel>
            <Select
              id={"filter-weeknumber-select"}
              value={weekNumber.toString()}
              onChange={handleChangeWeekNumber}
            >
              {Array.from({ length: 52 }, (_, i) => (
                <MenuItem
                  key={"filter-weeknumber-select-menu-item" + i}
                  value={52 - i}
                >
                  {52 - i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={"col-6 col-md-3"}>
          <div className={"fw-bold"}>Totaal uren:</div>
          <div>
            {totalHours} uur en {totalMinutes} minuten
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Filter;
