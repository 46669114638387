import React from "react";
import { Route, Routes } from "react-router-dom";
import Overview from "./Data.Overview";

type Props = {
  isAdmin: boolean;
};

const Data = ({ isAdmin }: Props) => {
  return (
    <Routes>
      <Route path="/" element={<Overview isAdmin />} />
    </Routes>
  );
};

export default Data;
